import { Component, Inject } from '@angular/core';
import { DatePipe } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { takeUntil } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';

import { BaseComponent } from '@ptg-shared/components';
import { Column, Row } from '@ptg-shared/controls/grid';
import { waringIcon } from '@ptg-shared/constance/listIcons.const';
import { deepClone } from '@ptg-shared/utils/common.util';
import { getDateString } from '@ptg-shared/utils/string.util';

import { EdiErrorType } from '@ptg-processing/features/payroll/services/models';
import { PayrollRunState } from '@ptg-processing/features/payroll/store/reducers';
import { DEFAULT_FULL_DATETIME } from '@ptg-processing/features/payroll/constants';
import { RegisterService } from '@ptg-shared/transaction/services/register.service';
import { getPaymentLevelErrorListSelector } from '@ptg-shared/transaction/store/selectors/register.selector';
import { ErrorMessage, GetPaymentLevelErrorListsRequest, PaymentErrorList } from '@ptg-shared/transaction/services/models/register.model';
import { getPaymentLevelErrorListsAction } from '@ptg-shared/transaction/store/actions/register.action';

@Component({
  selector: 'ptg-payment-error-list',
  templateUrl: './payment-error-list.component.html',
  styleUrls: ['./payment-error-list.component.scss'],
})
export class PaymentErrorListComponent extends BaseComponent {
  private readonly EdiErrorType = EdiErrorType;
  isLoading = true;
  errorListData: ((PaymentErrorList | ErrorMessage) & Row)[] = [];
  cloneData: any;
  columns: Column[] = [
    {
      name: 'errorCode',
      header: {
        title: 'Error Code',
      },
    },
    {
      name: 'errorDescription',
      header: {
        title: 'Description',
      },
      truncate: true,
      width: '70%',
    },
    {
      name: 'errorType',
      header: {
        title: 'Error Type',
      },
      cell: (row) => {
        return EdiErrorType[row.errorType] || '-';
      },
    },
  ];

  received = '';

  constructor(
    public datePipe: DatePipe,
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
    public dialogRef: MatDialogRef<PaymentErrorListComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { payrollRunId: string; transactionId: string; isPaymentLevel: boolean, bankName: string },
    private registerService: RegisterService,
    private payrollRunStore: Store<PayrollRunState>,
  ) {
    super();
    iconRegistry.addSvgIconLiteral('waringIcon', sanitizer.bypassSecurityTrustHtml(waringIcon));
  }

  ngOnInit(): void {
    super.ngOnInit();

    if (this.data?.isPaymentLevel) {
      let request: GetPaymentLevelErrorListsRequest = {
        payrollRunId: this.data.payrollRunId,
        transactionId: this.data.transactionId,
      };
      this.payrollRunStore.dispatch(
        getPaymentLevelErrorListsAction({
          request,
        }),
      );

      this.payrollRunStore
        .pipe(select(getPaymentLevelErrorListSelector), takeUntil(this.unsubscribe$))
        .subscribe((state) => {
          if (state?.success) {
            this.isLoading = state.isLoading;
            this.errorListData = (state.payload ?? []);
            this.received = getDateString((state as any).received);
          }
        });
    } else {
      this.registerService.getPayrollRunError(this.data.payrollRunId).subscribe(
        (result) => {
          this.isLoading = false;
          this.cloneData = deepClone(result);
          this.cloneData.dateTimeSubmitted = this.cloneData.dateTimeSubmitted
            ? this.datePipe.transform(new Date(getDateString(this.cloneData.dateTimeSubmitted)), DEFAULT_FULL_DATETIME)
            : null;
          this.errorListData = this.cloneData.errorMessage;
        },
        () => {
          this.isLoading = false;
        },
      );
    }
  }

  onClickClose(): void {
    this.dialogRef.close();
  }
}
