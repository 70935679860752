import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent } from '@ptg-shared/components/base.component';
import { filter, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import {
  PayeeDetailState,
  getHeaderConfigApplyAction,
  getHeaderDataApplyAction,
  paymentHeaderConfigurationInDialogSelector,
  selectHeaderConfigApply,
  selectHeaderDataApply,
} from '../../store';
import { Store, select } from '@ngrx/store';
import * as fromLayoutReducer from '@ptg-shared/layout/reducers';
import { EntityPropertyType } from '@ptg-entity-management/types/enums';
import { getDateString } from '@ptg-shared/utils/string.util';
import { DatePipe } from '@angular/common';
import { Config } from '@ptg-shared/controls/card-description/types/models';
import { FormBuilder, FormControl } from '@angular/forms';
import { HeaderItemConfig } from '@ptg-member/types/models/header-item-config.model';
import { HeaderPropertyConfig } from '@ptg-member/types/models/header-configuration.model';
import { HeaderBenefit } from '../../types/models';
import { DisplayedTabName, STRING_QUERY_PARAM } from '@ptg-shared/layout/constance/layout.const';
import { LayoutActions } from '@ptg-shared/layout/actions';
import { deepClone } from '@ptg-shared/utils/common.util';
import { PaymentHeaderConfigurationService } from '../../services';
import { ProfileNavigationConfigurationService } from '@ptg-member/services/profile-navigation-configuration.service';
import { MemberNavigationList } from '@ptg-member/types/models';
import { MatDialog } from '@angular/material/dialog';
import { LinkRelatedMemberComponent } from '../link-related-member/link-related-member.component';
import { BannerType } from '@ptg-shared/controls/banner/types/banner.model';

@Component({
  selector: 'ptg-payment-header',
  templateUrl: './payment-header.component.html',
  styleUrls: ['./payment-header.component.scss'],
})
export class PaymentHeaderComponent extends BaseComponent {
  @Input() shouldDisableNavigationButtonsInAllCases: boolean = false;
  @Input() targetIdFromDialog = '';
  @Input() isShowInteractionComponents = true;
  @Output() selectedHeaderBenefitEmitter = new EventEmitter();
  @Output() headerBenefitEmitter = new EventEmitter<HeaderBenefit[]>();
  @Output() headerConfigEmitter = new EventEmitter<HeaderItemConfig[]>();
  @Output() loadingEmitter = new EventEmitter<boolean>();

  memberId: string = '';
  menuId: string = '';
  entityViewId: string = '';
  entityReferenceLinkedId: string = '';
  entityId: string = '';
  headerItems: HeaderItemConfig[] = [];
  readonly EntityPropertyType = EntityPropertyType;
  status: any;
  navigationDisabled: {
    previous: boolean;
    next: boolean;
  } = {
    previous: true,
    next: true,
  };
  headerBenefits: HeaderBenefit[] = [];
  headerIdFormControl: FormControl = this.fb.control('');
  screenId: string = '';
  selectedHeaderBenefit?: HeaderBenefit;
  isLoading: boolean = true;
  selectUrlBeforeSearch: string = '';
  benefitId: string = '';
  benefitEntityDataId: string = '';
  isHistoryTab: boolean = false;
  accidentId?: string;
  queryParams?: any;
  @Input() customData?: { label: string; displayValue: any }[];

  // Only Payment Information screen should be able to show
  @Input() canLinkRelatedMember = false;
  // Show Link Related Member button if the benefit of the current Benefit ID is a dependent
  showLinkRelatedMember = false;

  @Output() changeBannerEmitter = new EventEmitter();

  constructor(
    public route: ActivatedRoute,
    private payeeDetailStore: Store<PayeeDetailState>,
    private store: Store<fromLayoutReducer.State>,
    public fb: FormBuilder,
    public router: Router,
    private paymentHeaderConfigurationService: PaymentHeaderConfigurationService,
    private profileNavigationConfigurationService: ProfileNavigationConfigurationService,
    private dialog: MatDialog,
  ) {
    super();
  }

  get selectedBenefitName(): string {
    return this.headerBenefits.find((el) => el.value === this.headerIdFormControl.value)?.displayValue ?? '-';
  }

  ngOnInit(): void {
    this.route.queryParams.pipe(takeUntil(this.unsubscribe$)).subscribe((queryParams) => {
      this.benefitId = queryParams.benefitId;
      this.benefitEntityDataId = queryParams.benefitEntityDataId;
    });
    this.route.params.pipe(takeUntil(this.unsubscribe$)).subscribe((params) => {
      this.memberId = this.targetIdFromDialog || params.id;
      this.menuId = params.menuId;
      this.entityViewId = params.viewId;
      this.isHistoryTab = this.router.url.includes(STRING_QUERY_PARAM.SEARCH);
      this.accidentId = params.accidentId;
      const entityReferenceLinkedId = new URLSearchParams(document.location.search).get('entityReferenceLinkedId');
      if (entityReferenceLinkedId) {
        this.entityReferenceLinkedId = entityReferenceLinkedId;
      }
    });

    // Open header in dialogs
    if (this.targetIdFromDialog) {
      this.selectHeaderConfigurationInDialogState();
      return;
    }

    // Open header in Payment Instruction Detail screen
    this.store.dispatch(
      LayoutActions.profileNavigationItemRequest({
        memberId: this.memberId,
        entityReferenceLinkedId: this.entityReferenceLinkedId,
      }),
    );

    this.selectProfileNavigationState();

    this.selectHeaderConfigurationState();
    this.selectAppliedHeaderConfigurationState();
  }

  private selectProfileNavigationState(): void {
    this.store
      .pipe(
        select(fromLayoutReducer.selectProfileNavigationState),
        filter((state) => state?.menu?.length > 0 && !state?.isHyperlink),
        take(1),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((state) => {
        const { entityId = '', memberNavigationMenu = [], profileName = '' } = state?.memberNavigationList ?? {};
        // Get queryParams benefitId & benefitEntityId
        this.queryParams = state.queryParams;
        // Get Entity ID
        this.entityId = entityId;

        // Get Screen ID
        const currentMenu = memberNavigationMenu.find(
          (x) => x.menuItems.find((item) => item.id === this.menuId) != null,
        );
        if (currentMenu) {
          const menuName = currentMenu.name ?? '';
          const menuItem = (currentMenu.menuItems ?? []).find((x) => x.id === this.menuId);
          const menuItemName = menuItem?.name ?? '';
          this.screenId = menuItem
            ? `${profileName?.replace(/\s/g, '')}_${menuName?.replace(/\s/g, '')}_${menuItemName?.replace(/\s/g, '')}_`
            : '';
        }

        // Get Header Configuration
        if (this.memberId && this.entityId) {
          this.payeeDetailStore.dispatch(
            getHeaderConfigApplyAction({ recordId: this.memberId, entityId: this.entityId }),
          );
        }
      });
  }

  private selectHeaderConfigurationState(): void {
    this.payeeDetailStore
      .pipe(
        select(selectHeaderConfigApply),
        tap((res) => (this.isLoading = !!res?.isLoading)),
        filter((res) => !!res && !res?.isLoading),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((data) => {
        if (!data?.payload) {
          this.loadingEmitter.emit(false);
          return;
        }

        const { headerId = '', headerItems = [], headerBenefits = [] } = data.payload;
        this.headerItems = headerItems;

        if (!headerBenefits.length) {
          this.loadingEmitter.emit(false);
          return;
        }

        this.headerBenefits = headerBenefits.map((ele: HeaderBenefit) => {
          return {
            ...ele,
            value: ele.benefitEntityDataId,
            displayValue: `${ele.benefitName} - ${ele.benefitId}`,
          };
        });
        this.headerBenefitEmitter.emit(this.headerBenefits);

        const previousSelectedHeaderBenefit = this.selectedHeaderBenefit;
        const currentSelectedHeaderBenefit =
          previousSelectedHeaderBenefit ||
          (this.queryParams?.benefitCode &&
            this.queryParams?.benefitEntityId &&
            this.headerBenefits.find(
              (item) =>
                item.benefitId === this.queryParams.benefitCode &&
                item.benefitEntityId === this.queryParams.benefitEntityId,
            )) ||
          (this.benefitEntityDataId && this.headerBenefits.find((item) => item.benefitEntityDataId === this.benefitEntityDataId)) ||
          (this.benefitId && this.headerBenefits.find((item) => item.benefitId === this.benefitId)) ||
          this.headerBenefits.find((item) => item.headerId === headerId);
        if (currentSelectedHeaderBenefit && this.entityId && this.memberId) {
          // Get applied Header Configuration
          const query = {
            recordId: this.memberId,
            benefitDataId: currentSelectedHeaderBenefit.benefitEntityDataId,
            benefitId: currentSelectedHeaderBenefit.benefitEntityId,
            entityId: this.entityId,
            headerId: currentSelectedHeaderBenefit.headerId,
            screenId: encodeURIComponent(this.screenId),
          };
          this.payeeDetailStore.dispatch(getHeaderDataApplyAction({ query }));

          // Append & Emit selected benefit option
          this.headerIdFormControl.setValue(currentSelectedHeaderBenefit.benefitEntityDataId);
          this.selectedHeaderBenefitEmitter.emit(currentSelectedHeaderBenefit);
          this.selectedHeaderBenefit = currentSelectedHeaderBenefit;
          this.updateNavigatorStatus();
          this.loadingEmitter.emit(false);
        }
      });
  }

  private selectAppliedHeaderConfigurationState(): void {
    this.payeeDetailStore
      .pipe(
        select(selectHeaderDataApply),
        tap((res) => (this.isLoading = !!res?.isLoading)),
        filter((res) => !!res && !res?.isLoading),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((data) => {
        if (!data?.success) return;

        const profileHeaders: HeaderPropertyConfig[] = (data?.payload?.resultProperties ?? [])
          .filter((x: HeaderPropertyConfig) => x.propertyName !== null)
          .map((item: HeaderPropertyConfig) => {
            if (item.type === EntityPropertyType.Binary) {
              return {
                ...item,
                value: item?.value,
              };
            }
            return {
              ...item,
              value:
                item.value?.maskedValue || item.value?.maskedValue === null ? item.value?.maskedValue : item?.value,
            };
          });

        this.headerItems = this.headerItems.map((item) => {
          const mappedValue = profileHeaders?.find(
            (x) =>
              (x.entityPropertyId === item.propertyId || x.entityPropertyId === item.propertyReferenceId) &&
              (x.entityReferencePropertyId === item.propertyReferenceId ||
                x.entityReferencePropertyId === item.propertyId),
          );

          let value = mappedValue?.value;

          if (mappedValue?.type === EntityPropertyType.Status) {
            //TBU Option Date
            this.status = {
              recordId: mappedValue?.recordId,
              entityComponentId: mappedValue?.entityComponentId,
              entityPropertyId: mappedValue?.entityPropertyId,
              options: profileHeaders?.find(
                (x) =>
                  x.entityPropertyId === item.propertyId && x.entityReferencePropertyId === item.propertyReferenceId,
              )?.options,
              value,
            };

            let status = mappedValue?.options?.find((item: any) => item.id === value?.status);

            let temp: any;
            if (item.option === 'Name') {
              temp = {
                icon: status?.icon,
                color: status?.color,
                name: status?.name,
              };
            } else if (item.option === 'Event') {
              temp = {
                icon: status?.icon,
                color: status?.color,
                tooltip: status?.name,
                name: status?.events?.find((item: any) => item.id === value?.event)?.name,
              };
            } else {
              temp = {
                icon: null,
                name: value?.statusDate ? new DatePipe('en-US').transform(value?.statusDate, 'MM/dd/yyyy') : null,
              };
            }
            value = temp;
          }
          if (
            (mappedValue?.type === EntityPropertyType.Address ||
              mappedValue?.type === EntityPropertyType['Person Name']) &&
            value &&
            typeof value === 'object' &&
            Object.values(value).every((item) => item === null)
          ) {
            value = null;
          }
          if (value && mappedValue?.type === EntityPropertyType['Date Time']) {
            value = getDateString(value);
          }
          if (
            value &&
            mappedValue?.type === EntityPropertyType.Identifier &&
            mappedValue?.configs?.autogenerated === 'true'
          ) {
            value = (mappedValue?.configs?.patternPrefix ?? '') + value + (mappedValue?.configs?.patternSuffix ?? '');
          }
          return {
            ...item,
            value,
            type: mappedValue?.type,
            options: mappedValue?.options,
            configs: mappedValue?.configs,
            recordLinkedId: mappedValue?.recordLinkedId
          };
        });
        this.headerConfigEmitter.emit(this.headerItems);

        // Show Link Related Member button if the benefit of the current Benefit ID is a dependent
        this.showLinkRelatedMember = !!data?.payload?.isMissingRelatedMember;
      });
  }

  private selectHeaderConfigurationInDialogState(): void {
    this.store
      .select(paymentHeaderConfigurationInDialogSelector)
      .pipe(
        filter((res) => !!res),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((state) => {
        const { headerItems = [], headerBenefits = [], selectedHeaderBenefit } = deepClone(state) ?? {};

        // Get Header Configuration
        this.headerItems = headerItems;
        this.headerBenefits = headerBenefits;
        // Append & Emit selected benefit option
        this.headerIdFormControl.setValue(selectedHeaderBenefit?.benefitEntityDataId);
        this.selectedHeaderBenefitEmitter.emit(selectedHeaderBenefit);
        this.selectedHeaderBenefit = selectedHeaderBenefit;
        this.updateNavigatorStatus();
        this.isLoading = false;
      });
  }

  checkNull4Address(value: any) {
    return !value || ['city', 'country', 'state', 'street1', 'street2', 'zipCode'].every((i) => value[i] === null);
  }

  getFractionalLengthDecimal(configs: Config): number | string {
    return configs?.fractionalLengthInput
      ? configs?.fractionalLengthInput
      : JSON.parse(configs?.calcucation ?? '""')?.fractionalLength;
  }

  getBenefitOptionChange(): void {
    // Stop action if the header is opened in dialog
    if (this.targetIdFromDialog) {
      return;
    }

    const selectedHeaderBenefit: HeaderBenefit | undefined = this.headerBenefits.find(
      (item) => item.benefitEntityDataId === this.headerIdFormControl.value,
    );

    if (selectedHeaderBenefit) {
      this.selectedHeaderBenefit = selectedHeaderBenefit;
      this.payeeDetailStore.dispatch(
        getHeaderConfigApplyAction({
          recordId: this.memberId,
          entityId: this.entityId,
          headerId: selectedHeaderBenefit.headerId,
        }),
      );
    }
  }

  // Navigate to next or previous benefit option by navigators
  changeBenefitOptionByNavigator(isNext = false): void {
    // Stop action if the header is opened in dialog
    if (this.targetIdFromDialog) {
      return;
    }

    const previousSelectedHeaderBenefitIndex = this.headerBenefits.findIndex(
      (x) => x.benefitEntityDataId === this.selectedHeaderBenefit?.benefitEntityDataId,
    );
    const currentSelectedHeaderBenefit = this.headerBenefits[previousSelectedHeaderBenefitIndex + (isNext ? 1 : -1)];

    this.headerIdFormControl.setValue(currentSelectedHeaderBenefit.benefitEntityDataId);
    this.selectedHeaderBenefit = currentSelectedHeaderBenefit;

    // Re-fetch getting Header Configuration on Benefit Option changes
    this.payeeDetailStore.dispatch(
      getHeaderConfigApplyAction({
        recordId: this.memberId,
        entityId: this.entityId,
        headerId: this.selectedHeaderBenefit.headerId,
      }),
    );
  }

  // Check navigator visibility status after benefit option changes
  private updateNavigatorStatus(): void {
    if (this.shouldDisableNavigationButtonsInAllCases === true) {
      this.navigationDisabled = { previous: true, next: true };
      return;
    }
    if (!this.headerBenefits?.length || this.headerBenefits?.length === 1) {
      this.navigationDisabled = { previous: true, next: true };
      return;
    }

    const index = this.headerBenefits.findIndex(
      (x) => x.benefitEntityDataId === this.selectedHeaderBenefit?.benefitEntityDataId,
    );
    const previous = index <= 0;
    const next = index === -1 || index >= this.headerBenefits.length - 1;
    this.navigationDisabled = { previous, next };
  }

  gotoPaymentInfo(item: HeaderItemConfig) {
    if (!item.recordLinkedId) {
      return;
    }
    let overviewView: MemberNavigationList;
    this.profileNavigationConfigurationService.getMemberNavigationList(item?.recordLinkedId, this.entityId)
    .pipe(
      switchMap(el => {
        overviewView = el;
        return this.paymentHeaderConfigurationService.getPaymentHeaderConfigApply(item?.recordLinkedId ?? '', el.entityId, this.selectedHeaderBenefit?.headerId);
      })
    )
    .subscribe(el => {
      if (el?.headerBenefits?.length) {
        const menu = overviewView?.memberNavigationMenu.find(item => item.name === 'Payment Information')?.menuItems.find(item => item && (item.entityViewName === 'Payment Information'));
        const url = `/member/system-view/payment-information/${menu?.id}/${menu?.entityViewId}/${item?.recordLinkedId}`;
        this.router.navigate([url], { queryParamsHandling: 'merge' });
      } else {
        const url = `${overviewView?.isOverviewDetailView
          ? '/member/detail-view/true'
          : '/member/summary-view/true'}/${overviewView.id}/${overviewView.overviewViewId}/${item?.recordLinkedId}?${STRING_QUERY_PARAM.PROFILE_NAVIGATE}&entityReferenceLinkedId=${this.entityId}`;

        this.store.dispatch(LayoutActions.clearProfileNavigationItemState());
        this.store.dispatch(
          LayoutActions.selectTab({
            tab: DisplayedTabName.IndividualParticipant,
            url,
          }),
        );
      }
    })
  }

  openLinkRelatedMember() {
    this.dialog.open<LinkRelatedMemberComponent>(LinkRelatedMemberComponent, {
      panelClass: 'dialog-full-screen',
      disableClose: true,
      autoFocus: false,
      data: {
        memberId: this.memberId,
        benefitEntityId: this.selectedHeaderBenefit?.benefitEntityId,
        benefitRecordId: this.selectedHeaderBenefit?.benefitEntityDataId,
      }
    })
    .afterClosed().subscribe((isSuccess: boolean) => {
      if (isSuccess) {
        // Show success banner
        this.changeBannerEmitter.emit({
          state: BannerType.Success,
          propertyName: 'Related Member',
          action: 'linked'
        });
        // Reload Payment Info screen with the data of the current Benefit ID
        this.getBenefitOptionChange();
      }
    })
  }
}
